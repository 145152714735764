// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("@/assets/imgs/harbour.jpg", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "body{background-image:linear-gradient(180deg,rgba(0,0,0,.2),rgba(0,0,0,.5)),url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");background-repeat:no-repeat;background-position:50%;background-attachment:fixed;background-size:cover;overflow-x:hidden}.AdminImageVault-img-area:hover,.Community-highlighted-section,.Community-tabs-wrapper h2:hover,.List-sorted-by-list div:hover,.MobileMenu-links,.MultiSelect-li:hover,.Tabs-content-area li:hover,.UserMessage-message-item:hover,.btn,.day-content:hover,.invitation-item:hover,.v-tooltip,.wow-color,.wow-color-hover{background-color:#a3bda4!important}.Tabs-content-area{border-color:#a3bda4!important}.AdminImageVault-toggle-image-gallery i:hover,.v-tooltip-triangle{color:#a3bda4!important}.MobileMenu-triangle{border-left-color:#a3bda4!important}.btn,.v-tooltip,.wow-color-darker{filter:brightness(95%)}.wow-color-bg{filter:brightness(118%)}.btn:hover{filter:brightness(90%)}.fa,.fa-regular,.fa-solid,.font-color,.v-tooltip,a,h1,h2,h5{color:#677a68}.MultiSelect-ul,.border-color,.date-input,.ql-editor,.select-input,button,input,textarea{border:1px solid #96af97}.MultiSelect-ul,.date-input,.ql-editor,.select-input,input,textarea{background-color:#dce7dd!important}.btn-admin{background-color:#f5c45c!important;border:1px solid #e7aa25!important}.admin-font-color,.btn-admin{color:#000!important}.btn-blur,.btn-gray{background-color:#ececec!important}.btn-blur{border-color:#d6d6d6}.info{background-color:#eeeab1}.failure{background-color:#fcdada!important}.success{background-color:#bdfcbd!important}", ""]);
// Exports
export default ___CSS_LOADER_EXPORT___;
