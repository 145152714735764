<script>
import TextEditor from '@/components/TextEditor.vue'
export default {
    components:{ TextEditor },
    props: ['model', 'prop', 'top','description']
}
</script>
<template>
       <section v-if="model != null" style="" class="opacity-admin-bg" :style="{'top': top}">
        <div>{{ description }}</div>
            <TextEditor :model="model" :prop="prop" :height="'500px'"></TextEditor>   
        </section>        
</template>

<style scoped>
    section{
        position: absolute;
        z-index:10; 
        left:-400px; 
        width: 400px;
        padding:20px 20px 45px 20px;
    }

    section div 
    {
        font-size: 13px;
        margin-bottom: 10px;
    }
</style>