<script>
export default {
    props: ['publicPage', 'customer', 'availableBookings', 'backgroundColor']
}
</script>
<template>
    <div class="public-footer" :style="{ 'background-color': backgroundColor }">
        <table>
            <tr>
                <td style="text-align: center;">
                    &copy; {{ new Date().getFullYear() }} {{ customer.name }} 
                </td>
                <td></td>
                <td>
            
                    <b>Meny</b> 
                    <ul>
                        <li>
                            Hem     
                        </li>
                        <li>
                            {{this.$store.state.settings.Community.singular}}     
                        </li>
                        <li v-if="publicPage.include_image_gallery">
                            {{this.$store.state.settings.ImageGallery.singular}}
                        </li>                     
                        <li v-if="publicPage.include_contact">
                            Kontakta Oss      
                        </li>                  
                    </ul>     
                </td>
                <td>
                    <b>Boka</b>
                    <ul>
                        <li v-for="booking in availableBookings">
                            {{ booking.name }}                                                                           
                        </li>
                    </ul>
                </td>
            </tr>
        </table>  
        <div class="public-footer-samfallify" :style="{ 'color': backgroundColor }">{{ customer.name }} är en del av jamiis&copy; härliga community.</div>                  
    </div>
</template>

<style scoped>
  .public-footer
{
    background-color: black;
    width:100%;
    filter: brightness(90%);
    padding: 70px 0px;
}

.public-footer table
{
    width: 70%;
    margin: auto;
}

.public-footer td
{
    vertical-align: top;
}

.public-footer-samfallify 
{
    position: absolute;
    bottom: 7px;
    right: 7px;
    font-size: 12px;  
    filter: brightness(70%);
}
.public-footer ul
{
    list-style: none;
    padding: 0px;
}

.public-footer li
{
   margin-bottom:10px;
   cursor: pointer;
}

.public-footer i
{
    filter: brightness(110%);
    font-size: 70pt;
    padding: 15px;
}
</style>