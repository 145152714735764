<script>
export default {
    props: ['top', 'left']
}
</script>
<template>
    <section class="opacity-admin-bg" :style="{ 'left': left, 'top': top }">
        <slot></slot> 
    </section>
</template>

<style scoped>
    section {
        position: absolute;
        padding:10px;
        width: 300px;
        z-index: 10;
    }
</style>