  <script>
  export default {
    name: "SvgIcon",
    props: {
        publicPage: { 
            type: Object,
            default: null
        },
        color: {
            type: String,
            default: "#ffffff",
        },
        showMenu: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {    
            svgs: [
                { type: 'default', svgType: 'path', height: '20px', viewBox:'0 0 100 20', points: 'M0,19 100,19 100,20 0,20 Z' },
                { type: 'manyWaves', svgType: 'path', height: '20px', viewBox:'0 0 240 20', points: 'M0,10 C20,0 40,20 60,10  C80,0 100,20 120,10  C140,0 160,20 180,10  C200,0 220,20 240,10 L240,20 L0,20 Z' },
                { type: 'waves', svgType: 'path', height: '50px', viewBox:'0 0 100 20', points: 'M0,10 C20,0 40,20 60,10 C80,0 100,20 120,10 L100,20 L0,20 Z' },
                { type: 'bowlUp', svgType: 'path', height: '50px', viewBox:'0 0 100 50', points: 'M0,50  H100  L100,50  Q50,10 0,50 Z' },
                { type: 'bowlDown', svgType: 'path', height: '30px', viewBox:'0 0 100 30', points: 'M0,0 Q40,30 100,0 L100,30 L0,30 Z' },
                { type: 'triangleLeft', svgType: 'polygon', height: '30px', viewBox:'0 0 100 30', points: '0,30 100,30 0,0' },
                { type: 'triangleRight', svgType: 'polygon', height: '30px', viewBox:'0 0 100 30', points: '0,30 100,30, 100,0' },
            ]        
        }
    },
    computed:
    {
        svg() { return this.svgs.find(x => x.type == this.publicPage.svg_transition)  }
    }
    
  };
  </script>
<template> 
    <div v-if="svg != null"> 
        <svg
            xmlns="http://www.w3.org/2000/svg"
            :viewBox="svg.viewBox"
            preserveAspectRatio="none"
            width="100%"
            :height="svg.height"
            class="svg-icon"
            >

            <path v-if="svg.svgType == 'path'"
            :d="svg.points"
            :fill="color"
            />

            <polygon v-if="svg.svgType=='polygon'" :points="svg.points" :fill="color" />
        </svg>
    </div>
    <div v-if="showMenu" style="" class="SvgTransition-menu opacity-admin-bg">
        Välj övergång mellan bild och text.
        <div style="display: flex; gap: 6px;">
            <div v-for="svg in svgs" @click="publicPage.svg_transition = svg.type" style="cursor: pointer">
                <svg :style="{ 'opacity': svg.type == publicPage.svg_transition ? '1' : '0.4' }"
                    xmlns="http://www.w3.org/2000/svg"
                    :viewBox="svg.viewBox"
                    preserveAspectRatio="none"
                    width="60px"
                    height="15px"
                    class="svg-icon-minimized"
                    >

                    <path v-if="svg.svgType == 'path'"
                    :d="svg.points"
                    fill="#677a68"
                    />

                    <polygon v-if="svg.svgType=='polygon'" :points="svg.points" fill="#677a68" />
                </svg>
            </div>
        </div>
    </div>
</template>
  
  
  <style scoped>
  .svg-icon {
    display: block;
    position: absolute;
    bottom: 0px;
    width: 100%;
  }
  .svg-icon-minimized {
    display: block;
    opacity: 0.4;
    bottom: 0px;
    width: 60px;
  }
  
  .svg-icon-minimized:hover {
    display: block;
    bottom: 0px;
    width: 60px;
    opacity: 1 !important;
  }

  .SvgTransition-menu {
    position: absolute; z-index:10; bottom: -85px; right: -10px; padding:10px; font-size: 13px;
  }
  </style>
  