<script>
export default {
    props: ['bookingItem', 'publicPage', 'headerColor']
}
</script>
<template>
    <div class="overflow-auto padding-15" style="width:80%; margin: auto;">                       
        <div v-html="this.$adaptHtml.adaptHtml(bookingItem.information, headerColor)"></div>            
    </div>
    <div class="overflow-auto padding-15 info" style="width:80%; margin: auto;">                       
        <div v-html="this.$adaptHtml.adaptHtml(bookingItem.rules, headerColor)"></div>            
    </div>
    <div class="fake-calendar">
        <div class="preview">
            <p class="info-box">
                Detta är bara en förhandsgranskning. Gå till den publika sidan för att boka/testa bokningen.
            </p>
        </div>
        <div>
            <b style="border-top: 1px solid #ddd;">Mån</b>
            <b style="border-top: 1px solid #ddd;">Tis</b>
            <b style="border-top: 1px solid #ddd;">Ons</b>
            <b style="border-top: 1px solid #ddd;">Tors</b>
            <b style="border-top: 1px solid #ddd;">Fre</b>
            <b style="border-top: 1px solid #ddd;">Lör</b>
            <b style="border-top: 1px solid #ddd;">Sön</b>
        </div>
        <div>
            <i>1</i>
            <i>2</i>
            <i>3</i>
            <i>4</i>
            <i>5</i>
            <i>6</i>
            <i>7</i>
        </div>
        <div>
            <i>8</i>
            <i>9</i>
            <i>10</i>
            <i>11</i>
            <i>12</i>
            <i>13</i>
            <i>14</i>
        </div>
        <div>
            <i>15</i>
            <i>16</i>
            <i>17</i>
            <i>18</i>
            <i>19</i>
            <i>20</i>
            <i>21</i>
        </div>
        <div>
            <i>22</i>
            <i>23</i>
            <i>24</i>
            <i>25</i>
            <i>26</i>
            <i>27</i>
            <i>28</i>
        </div>
        <div>
            <i>29</i>
            <i>30</i>
            <i>31</i>                              
        </div>
    </div>
</template>

<style scoped>
 
.fake-calendar
{
    width: 80%;
    margin: auto;
    margin-top: 20px;
    margin-bottom: 20px;
    position: relative;
}

.fake-calendar .preview
{
  position: absolute;
  width: calc(100% - 100px);
  background-color: rgba(255,255,255,0.8);
  height: 400px;
  border: 0px;
  text-align: center;
  padding: 50px;
  padding-top: 180px;
}

.fake-calendar div
{
    border-left: 1px solid #ddd;
}

.fake-calendar b{
    display: inline-block;
    width: 14%;
    text-align: center;
    padding:5px 0px;
    border-right: 1px solid #ddd;
    border-bottom: 1px solid #ddd;
}

.fake-calendar i{
    display: inline-block;
    width: calc(14% - 7px);
    height: 100px;
    padding: 5px 7px 0px 0px;
    text-align: right;
    border-right: 1px solid #ddd;
    border-bottom: 1px solid #ddd;
}
</style>