<script>
import  { Sketch }  from '@ckpack/vue-color';
export default {
    components:{ Sketch },
    props: ['colors', 'location', 'text', 'top', 'left', ],
    data(){
      return {
          color: '',
          showPicker: false
      }
    },
    watch:
    {
      color(newvalue){
        if(typeof newvalue === 'object') {
          var color = this.colors.find(x => x.location == this.location);
          if(color)
          {
            color.color = newvalue.hex;
          }
          else
          {
            this.colors.push({ location: this.location, id: 0, color: newvalue.hex })
          }
        }
      }
    },
    computed: {
      displayColor() {
        var color = this.colors.find(x => x.location == this.location);
        if(color == null) { return '' }

        return color.color;
      }
    }
}
</script>
<template>
  <section class="opacity-admin-bg" :style="{ 'top': top, 'left': left }">
    <div class="ColorPickerPreview" :style="{ 'background-color': displayColor }" @click="showPicker = !showPicker">
      <span>{{ text }}</span>
      <i class="fa-solid fa-pencil"></i>
    </div>
    <div v-if="showPicker" class="ColorPicker-picker">
        <Sketch v-model="color"></Sketch>
        <button class="btn float-right" @click="showPicker = false">Klar</button>
    </div>
  </section>
</template>

<style scoped>
  section {
    position:absolute;
    z-index: 10;
  }
  .ColorPickerPreview
  {
    border-radius: 10px;
    cursor: pointer;
    margin-top: 5px;
    font-size: 13px;
    font-weight: 500;
    padding: 7px;
    margin: 5px;
  }
  
  .ColorPickerPreview span
  {
    color: white;
    opacity: 0.9;
    text-shadow: -1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000, 1px 1px 0 #000;
  }
  
  .ColorPickerPreview i
  {
    float: right;
    margin: 4px;
    opacity: 0.3;
    color: black;
  }



  .ColorPicker-picker
  {
    background-color: white;
    border-radius: 4px;
    border: 1px solid black;
    width: 220px;
    padding: 5px;
    overflow: auto;
    position: absolute;
    z-index: 200;
  }
  .vc-sketch
  {
    border-radius: 0px !important;
    box-shadow: none !important;
  }
</style>